import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      { path: '/', name: 'index', component: () => import('@/views/index/index.vue'), meta: { title: '首页' } },
      { path: '/aboutUs', name: 'aboutUs', component: () => import('@/views/aboutUs/aboutUs.vue'), meta: { title: '关于凝聚' } },
      { path: '/business', name: 'business', component: () => import('@/views/business/business.vue'), meta: { title: '业务介绍' } },
      { path: '/service', name: 'service', component: () => import('@/views/service/service.vue'), meta: { title: '服务项目' } },
      { path: '/application', name: 'application', component: () => import('@/views/application/application.vue'), meta: { title: '入驻申请' } }
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // to去哪  from  来自
  if (to.meta.title) {
    if(to.meta.title == "首页") {
      document.title = "福州凝聚空间"
    }else {
      document.title = to.meta.title + " - 福州凝聚空间"
    }
  }
  next();
})


router.afterEach(() => {
  window.scrollTo(0, 0); // 切换路由之后滚动条始终在最顶部
});
export default router
