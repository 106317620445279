<template>
  <footer class="footer">
    <div class="footerContainer">
    <div class="row1">
      <div class="link">
        <p @click="skip('aboutUs')">关于凝聚</p>
      </div>

      <div class="cooperate">
        <p @click="skip('business')">业务介绍</p>
      </div>

      <div class="service">
        <p @click="skip('service')">服务项目</p>
      </div>

      <div class="QRCode">
        <p @click="skip('application')">入驻申请</p>
        <img height="120" width="120" src="@/assets/logo/QRCode.jpg"  />
      </div>
    </div>
    <div class="footerCopyright">©2021 福州凝聚信息科技有限公司. <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color:white; text-decoration: none; text-align: center; padding-bottom:3px;">闽ICP备2021006344号-1</a></div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    skip(url) {
      this.$router.push(url);
    }
  }
}
</script>

<style scoped>
.footer {
  width: 100%;
  margin: auto;
  padding: 30px 0;
  background-color: black;
  color: white;
}
.footerContainer{
  width: 1400px;
  margin: auto;
}
.link {
  margin-right: 30px;
}
.cooperate {
  margin-right: 30px;
}
.link p, .cooperate p, .service p, .QRCode p {
  cursor: pointer;
}
.row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.row1 p {
  margin: 0;
}
.QRCode img {
  margin-top: 20px;
}
.footerCopyright {
  text-align: center;
}
</style>