import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import zhCn from 'element-plus/lib/locale/lang/zh-cn.js'
import axios from "axios";
const app = createApp(App)

app.config.globalProperties.$axios = axios;

app.config.globalProperties.getProhibit = function () {//解禁按钮
  setTimeout(() => {
    this.disable = false;
  },1500);
}

app.use(ElementPlus, {
  locale: zhCn,
});

app.config.globalProperties.getToken = function (msg,type) {//消息提示
  const message = this.$message({
    message: msg,
    offset: 80,
    duration: 0,
    type: type
  });
  setTimeout(()=>{
    message.close();
  },1500);
}

let timeout = null;

app.config.globalProperties.anomaly = function () {//消息提示
  if(timeout){
    clearTimeout(timeout)
  }
  timeout = setTimeout(() => {
    const msg = this.$message({
      message: "请求异常，请稍后重试！",
      offset: 80,
      duration: 0,
      type: "error"
    });
    setTimeout(()=>{
      msg.close();
    }, 1500)
  }, 500);
}
/* app.mount('#app') */

app.use(store).use(router).mount('#app')

