<template>
  <div class="head">

    <!-- logo -->
    <div class="logoImgContainer">
      <img class="logo" src="@/assets/logo/homeLogo.png" />
    </div>

    <!-- 导航 -->
    <div class="navContainer">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        router
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/aboutUs">关于凝聚</el-menu-item>
        <el-menu-item index="/business">业务介绍</el-menu-item>
        <el-menu-item index="/service">服务项目</el-menu-item>
        <el-menu-item index="/application">入驻申请</el-menu-item>
      </el-menu>
      <div class="line"></div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "/index",
    }
  },
  created() {
    this.routing();
  },
  methods: {
    routing() {
      this.activeIndex = this.$route.path;
    },

    handleSelect(key, keyPath) {
      if(key != '' && key != null){
        this.activeIndex = key;
      }
    },
  }
}
</script>

<style scoped>
.head{
  display: flex;
  flex-direction: row;
  text-align: center ;
  justify-content: center;
  padding: 10px 0;
}
.head .el-menu {
  width: 1000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: none;
}
.head .el-menu-item {
  font-size: 1.25rem;
}
.logoImgContainer {
  margin-top: 10px;
  margin-right: 10px;
  width: 60px;
  height: 45px;
}
.logo {
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none;
  display: block;
}
</style>