<template>
  <div class="container">
    <Head ref="child"></Head>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /srcHead
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Head,
    Footer
  },
  data() {
    return {
      
    };
  },
  watch:{
    $route:{
      handler(val,oldval){
        this.$refs.child.routing();
      },
    },
  },
};
</script>

<style scoped>
.container {
  width:100%;
  min-width:1400px;
  /*max-width:1900px;*/
  margin: auto;
  /* width:expression_r(Math.max(1300, Math.min(1900, document.body.offsetWidth*0.99))+"px" ); */
  overflow-x: hidden;
}
/* .el-menu-item {
  font-size: 20px;
} */
</style>