<template>
  <router-view/>
</template>

<style>
html, body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: "微软雅黑", "PingFang SC", "Helvetica Neue", Helvetica, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*overflow: hidden;*/
}
</style>
